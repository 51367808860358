import * as React from "react";
import { useQuery } from "@tanstack/react-query";

import Select from "react-select";

const colorSchemes = {
  appStatus: {
    Declined: "#ff0000",
    Approved: "#99cc00",
    Withdraw: "#ff0000",
  },
  merchantStatus: {
    Live: "#99cc00",
    UW: "#ffcc00",
    "Rejected Processor": "#ff0000",
  },
};

const baseStyles = {
  control: (base) => ({
    ...base,
    border: "none",
    boxShadow: "none",
  }),
};

const createColoredStyles = (colorScheme) => ({
  ...baseStyles,
  option: (provided, state) => {
    const color = colorScheme?.[state.data.value];
    if (!color) return provided;

    return {
      ...provided,
      backgroundColor: state.isSelected
        ? color
        : state.isFocused
          ? `${color}22`
          : "white",
      color: state.isSelected ? "white" : color,
      ":active": {
        backgroundColor: color,
        color: "white",
      },
    };
  },
  singleValue: (provided, state) => ({
    ...provided,
    color: colorScheme?.[state.data.value] || provided.color,
  }),
});

export function SelectCell({
  getValue,
  row,
  column,
  table,
  url,
  colorSchemeKey,
}) {
  const initialValue = getValue();
  const [value, setValue] = React.useState(initialValue || undefined);
  const [selectOptions, setSelectOptions] = React.useState([]);

  const currentColorScheme = colorSchemeKey
    ? colorSchemes[colorSchemeKey]
    : null;

  const styles = currentColorScheme
    ? createColoredStyles(currentColorScheme)
    : baseStyles;

  React.useEffect(() => {
    setValue(initialValue || undefined);
  }, [initialValue]);

  // Grab data from the API endpoint
  const { data: options = [] } = useQuery({
    queryKey: ["selectOptions", url],
    queryFn: async () => {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`Failed to fetch options: ${url}`);
      }

      return response.json();
    },
  });

  React.useEffect(() => {
    if (JSON.stringify(selectOptions) !== JSON.stringify(options)) {
      setSelectOptions(options);
    }
  }, [options, selectOptions]);

  const onChange = (newValue) => {
    setValue(newValue || undefined);
    table.options.meta?.updateData(
      row.index,
      column.id,
      newValue ? newValue.value : "",
    );
  };

  return (
    <Select
      options={selectOptions}
      styles={styles}
      isClearable={true}
      backspaceRemovesValue={true}
      menuPortalTarget={document.querySelector("body")}
      defaultValue={value}
      value={value}
      onChange={onChange}
    />
  );
}
