import * as React from "react";
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";

import {
  DndContext,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { restrictToHorizontalAxis } from "@dnd-kit/modifiers";
import {
  arrayMove,
  SortableContext,
  horizontalListSortingStrategy,
} from "@dnd-kit/sortable";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

import { useQueryClient } from "@tanstack/react-query";

import consumer from "../channels/consumer";

import { DeploymentTableColumns } from "./DeploymentTable/Columns";
import {
  useAddDeployment,
  useDeleteDeployment,
  useDeployments,
  useUpdateDeployment,
} from "./DeploymentTable/Deployments";

import {
  getUserTableState,
  updateUserTableState,
} from "./DeploymentTable/UserState";

const DraggableTableHeader = ({ header, table }) => {
  const { attributes, isDragging, listeners, setNodeRef, transform } =
    useSortable({ id: header.column.id });

  const style = {
    opacity: isDragging ? 0.8 : 1,
    position: "relative",
    transform: CSS.Translate.toString(transform),
    transition: "width transform 0.2s east-in-out",
    whiteSpace: "nowrap",
    width: header.column.getSize(),
    zIndex: isDragging ? 1 : 0,
  };

  return (
    <th colSpan={header.colSpan} ref={setNodeRef} style={style}>
      <div className="d-flex gap-1">
        <button {...attributes} {...listeners} className="drag-handle">
          <i className="fa-solid fa-grip-vertical"></i>
        </button>

        {header.isPlaceholder
          ? null
          : flexRender(header.column.columnDef.header, header.getContext())}
      </div>

      <div
        {...{
          onDoubleClick: () => header.column.resetSize(),
          onMouseDown: header.getResizeHandler(),
          onTouchStart: header.getResizeHandler(),
          className: `resizer ${
            table.options.columnResizeDirection
          } ${header.column.getIsResizing() ? "isResizing" : ""}`,
          style: {
            transform: "",
          },
        }}
      />
    </th>
  );
};

const DragAlongCell = ({ cell }) => {
  const { isDragging, setNodeRef, transform } = useSortable({
    id: cell.column.id,
  });

  const style = {
    opacity: isDragging ? 0.8 : 1,
    position: "relative",
    transform: CSS.Translate.toString(transform),
    transition: "width transform 0.2 east-in-out",
    width: cell.column.getSize(),
    zIndex: isDragging ? 1 : 0,
  };

  return (
    <td style={style} ref={setNodeRef}>
      {flexRender(cell.column.columnDef.cell, cell.getContext())}
    </td>
  );
};

const DeploymentTable = () => {
  const columnResizeMode = "onChange";
  const columnResizeDirection = "ltr";
  const [pagination, setPagination] = React.useState({
    pageIndex: 0,
    pageSize: 25,
  });

  // Empty data for the table
  const defaultData = React.useMemo(() => [], []);

  const queryClient = useQueryClient();

  // Deployment Hooks
  const { data: deployments, isLoading, error } = useDeployments(pagination);
  const addDeploymentMutation = useAddDeployment(pagination);
  const updateDeploymentMutation = useUpdateDeployment(pagination);
  const deleteDeploymentMutation = useDeleteDeployment(pagination);

  const [columns] = React.useState(() => [...DeploymentTableColumns]);

  // Load prefences for table column order and visiblity
  const [preferences, setPreferences] = React.useState(null);
  const [isLoadingPreferences, setIsLoadingPreferences] = React.useState(true);

  React.useEffect(() => {
    async function loadData() {
      const response = await getUserTableState();
      if (response) {
        setPreferences(response);
      }
      setIsLoadingPreferences(false);
    }
    loadData();
  }, []);

  // Use loaded preferences for both states
  const [columnOrder, setColumnOrder] = React.useState(
    () => preferences?.columnOrder ?? DeploymentTableColumns.map((c) => c.id),
  );

  const [columnVisibility, setColumnVisibility] = React.useState(
    () => preferences?.columnVisibility ?? {},
  );

  React.useEffect(() => {
    if (preferences) {
      setColumnOrder(
        preferences.column_order ?? DeploymentTableColumns.map((c) => c.id),
      );
      setColumnVisibility(preferences.column_visibility ?? {});
    }
  }, [preferences]);

  // Save user preferences to the database
  React.useEffect(() => {
    async function updateData() {
      if (!isLoadingPreferences) {
        const debouncedSave = setTimeout(async () => {
          await updateUserTableState(columnOrder, columnVisibility);
        }, 500);
        return () => clearTimeout(debouncedSave);
      }
    }
    updateData();
  }, [columnOrder, columnVisibility, isLoadingPreferences]);

  // Setup ActionCable for collabrative editing
  const [channel, setChannel] = React.useState(null);
  React.useEffect(() => {
    const channel = consumer.subscriptions.create("DeploymentsTableChannel", {
      received(data) {
        handleWebSocketData(data);
      },
    });

    setChannel(channel);
  }, []);

  const handleWebSocketData = (data) => {
    const currentData = queryClient.getQueryData(["deployments", pagination]);

    if (!currentData?.rows) {
      console.log("no current deployment data available");
      return;
    }

    switch (data.type) {
      case "CELL_UPDATE":
        const rowIndex = currentData.rows.findIndex(
          (row) => row.id.toString() === data.row_id.toString(),
        );

        if (rowIndex === -1) return;

        queryClient.setQueryData(["deployments", pagination], (old) => {
          if (!old) return old;

          const newRows = [...old.rows];
          newRows[rowIndex] = {
            ...newRows[rowIndex],
            ...data.changes,
          };

          return {
            ...old,
            rows: newRows,
          };
        });
        break;

      case "ROW_ADDED":
        queryClient.invalidateQueries(["deployments", pagination]);
        break;

      case "ROW_REMOVED":
        queryClient.setQueryData(["deployments", pagination], (old) => {
          if (!old) return old;

          return {
            ...old,
            rows: old.rows.filter(
              (row) => row.id.toString() !== data.row_id.toString(),
            ),
            rowCount: old.rowCount - 1,
          };
        });
        break;

      case "CELL_SELECTED":
        const {
          row_id: selectedRowId,
          cell_id: selectedCellId,
          user: userId,
          current_user: currentUser,
        } = data;

        setSelectedCells((prev) => ({
          ...prev,
          [`${selectedRowId}-${selectedCellId}`]: {
            currentUser,
            userId,
            timestamp: new Date(data.timestamp),
          },
        }));
        break;

      case "CELL_DESELECTED":
        const { row_id: deselectedRowId, cell_id: deselectedCellId } = data;

        setSelectedCells((prev) => {
          const newSelections = { ...prev };
          delete newSelections[`${deselectedRowId}-${deselectedCellId}`];
          return newSelections;
        });
        break;
    }
  };

  const [selectedCells, setSelectedCells] = React.useState({});
  const currentUserId = document.head.querySelector(
    'meta[name="user_id"]',
  ).content;

  const table = useReactTable({
    data: deployments?.rows ?? defaultData,
    columns: columns,
    state: {
      columnOrder,
      columnVisibility,
      pagination,
    },
    onColumnOrderChange: setColumnOrder,
    onColumnVisibilityChange: setColumnVisibility,
    onPaginationChange: setPagination,
    columnResizeMode,
    columnResizeDirection,
    rowCount: deployments?.rowCount,
    manualPagination: true,
    getRowId: (row) => row.id.toString(),
    getCoreRowModel: getCoreRowModel(),
    meta: {
      selectedCells,
      currentUserId: currentUserId,
      updateData: async (rowIndex, columnId, value) => {
        if (!deployments) return;

        const deployment = deployments?.rows[rowIndex];
        updateDeploymentMutation.mutate({
          deploymentId: deployment.id,
          columnId,
          newValue: value,
        });
      },
      addRow: () => {
        addDeploymentMutation.mutate();
      },
      removeRow: async (rowIndex) => {
        if (!deployments) return;

        const deployment = deployments?.rows[rowIndex];
        deleteDeploymentMutation.mutate(deployment.id);
      },
      cellSelected: (rowId, cellId) => {
        channel.perform("cell_selected", {
          cell_id: cellId,
          row_id: rowId,
        });
      },
      cellDeselected: (rowId, cellId) => {
        channel.perform("cell_deselected", {
          cell_id: cellId,
          row_id: rowId,
        });
      },
    },
  });

  // Reorder columns after drag & drop
  function handleDragEnd(event) {
    const { active, over } = event;
    if (active && over && active.id !== over.id) {
      setColumnOrder((columnOrder) => {
        const oldIndex = columnOrder.indexOf(active.id);
        const newIndex = columnOrder.indexOf(over.id);

        return arrayMove(columnOrder, oldIndex, newIndex);
      });
    }
  }

  const sensors = useSensors(
    useSensor(MouseSensor, {}),
    useSensor(TouchSensor, {}),
    useSensor(KeyboardSensor, {}),
  );

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <DndContext
      collisionDetection={closestCenter}
      modifiers={[restrictToHorizontalAxis]}
      onDragEnd={handleDragEnd}
      sensors={sensors}
    >
      <div className="d-flex justify-content-between mb-4">
        <div className="dropdown">
          <button
            className="btn btn-outline-primary dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            data-bs-auto-close="outside"
            aria-expanded="false"
          >
            <i className="fa-solid fa-sliders me-2"></i>
            Display
          </button>
          <ul className="dropdown-menu">
            {table
              .getAllLeafColumns()
              .filter(
                (column) =>
                  typeof column.accessorFn !== "undefined" &&
                  column.getCanHide(),
              )
              .map((column) => {
                return (
                  <li key={column.id}>
                    <button
                      className="dropdown-item d-flex justify-content-between align-items-center"
                      type="button"
                      onClick={(e) => {
                        e.preventDefault();
                        column.toggleVisibility();
                      }}
                    >
                      {column.columnDef.meta.name}

                      {column.getIsVisible() ? (
                        <i className="fa-solid fa-check"></i>
                      ) : (
                        ""
                      )}
                    </button>
                  </li>
                );
              })}
          </ul>
        </div>
      </div>

      <div
        className="deployments-table-container"
        style={{ direction: table.options.columnResizeDirection }}
      >
        <table
          className="table table-bordered deployments-table"
          {...{
            style: {
              width: table.getCenterTotalSize(),
            },
          }}
        >
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                <SortableContext
                  items={columnOrder}
                  strategy={horizontalListSortingStrategy}
                >
                  {headerGroup.headers.map((header) => (
                    <DraggableTableHeader
                      key={header.id}
                      header={header}
                      table={table}
                    />
                  ))}
                </SortableContext>
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id} id={`row-${row.id}`}>
                {row.getVisibleCells().map((cell) => (
                  <SortableContext
                    key={cell.id}
                    items={columnOrder}
                    strategy={horizontalListSortingStrategy}
                  >
                    <DragAlongCell key={cell.id} cell={cell} />
                  </SortableContext>
                ))}
              </tr>
            ))}
          </tbody>
          <tfoot className="border-t">
            <tr>
              <th colSpan={table.getCenterLeafColumns().length}>
                <button
                  className="btn btn-primary btn-sm"
                  onClick={table.options.meta.addRow}
                >
                  Add New +
                </button>
              </th>
            </tr>
          </tfoot>
        </table>
      </div>

      <div className="d-flex items-center gap-2 mt-2 mb-4">
        <nav>
          <ul className="pagination mb-0">
            <li className="page-item">
              <button
                className="page-link"
                onClick={() => table.firstPage()}
                disabled={!table.getCanPreviousPage()}
              >
                {"<<"}
              </button>
            </li>
            <li className="page-item">
              <button
                className="page-link"
                onClick={() => table.previousPage()}
                disabled={!table.getCanPreviousPage()}
              >
                {"<"}
              </button>
            </li>
            <li className="page-item">
              <button
                className="page-link"
                onClick={() => table.nextPage()}
                disabled={!table.getCanNextPage()}
              >
                {">"}
              </button>
            </li>
            <li className="page-item">
              <button
                className="page-link"
                onClick={() => table.lastPage()}
                disabled={!table.getCanNextPage()}
              >
                {">>"}
              </button>
            </li>
          </ul>
        </nav>

        <span className="d-flex align-items-center gap-1">
          <div>Page</div>
          <strong>
            {table.getState().pagination.pageIndex + 1} of{" "}
            {table.getPageCount().toLocaleString()}
          </strong>
        </span>
      </div>
    </DndContext>
  );
};

export default DeploymentTable;
