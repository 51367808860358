import * as React from "react";
import { useQuery } from "@tanstack/react-query";

import CreatableSelect from "react-select/creatable";

const reactSelectStyles = {
  control: (base) => ({
    ...base,
    border: "none",
    boxShadow: "none",
  }),
};

export function CreatableSelectCell({
  getValue,
  row,
  column,
  table,
  url,
  objectName,
}) {
  const initialValue = getValue() || {};
  const [value, setValue] = React.useState(
    initialValue?.id
      ? { value: initialValue.id, label: initialValue.name }
      : undefined,
  );
  const [isLoading, setIsLoading] = React.useState(false);
  const [selectOptions, setSelectOptions] = React.useState([]);

  React.useEffect(() => {
    if (initialValue?.id) {
      setValue({ value: initialValue.id, label: initialValue.name });
    } else {
      setValue(undefined);
    }
  }, [initialValue]);

  // Grab data from the API endpoint
  const { data: options = [] } = useQuery({
    queryKey: ["selectOptions", url],
    queryFn: async () => {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`Failed to fetch options: ${url}`);
      }

      return response.json();
    },
  });

  React.useEffect(() => {
    if (JSON.stringify(selectOptions) !== JSON.stringify(options)) {
      setSelectOptions(
        options.map((option) => ({ value: option.id, label: option.name })),
      );
    }
  }, [options]);

  const onChange = (newValue) => {
    setValue(newValue || undefined);
    table.options.meta?.updateData(
      row.index,
      column.id,
      newValue ? newValue.value : "",
    );
  };

  const onCreateOption = async (inputValue) => {
    setIsLoading(true);

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token":
          document
            .querySelector('[name="csrf-token"]')
            ?.getAttribute("content") || "",
      },
      body: JSON.stringify({
        [objectName]: {
          name: inputValue,
        },
      }),
    });

    if (!response.ok) {
      throw new Error(`Failed to create ${objectName}`);
    }

    const data = await response.json();
    const newOption = { value: data.id, label: data.name };

    setSelectOptions((prev) => [...prev, newOption]);
    setValue({ value: data.id, label: data.name });
    table.options.meta?.updateData(row.index, column.id, data.id);

    setIsLoading(false);
  };

  return (
    <CreatableSelect
      options={selectOptions}
      styles={reactSelectStyles}
      isClearable={true}
      backspaceRemovesValue={true}
      menuPortalTarget={document.querySelector("body")}
      value={value}
      onChange={onChange}
      onCreateOption={onCreateOption}
      isLoading={isLoading}
    />
  );
}
