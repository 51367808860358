import React from "react";

export function InputCell({ getValue, row, column, table, inputType }) {
  const initialValue = getValue();
  const [value, setValue] = React.useState(initialValue);

  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const onChange = (e) => {
    setValue(e.target.value);
  };

  // Format date for input if type is date
  const displayValue = React.useMemo(() => {
    if (value === null) return "";
    if (inputType === "date" && value instanceof Date) {
      return value.toISOString().split("T")[0];
    }
    return value;
  }, [value, inputType]);

  const onBlur = () => {
    if (inputType === "date" && value) {
      const dateValue = new Date(value);
      if (!isNaN(dateValue.getTime())) {
        setValue(dateValue.toISOString().split("T")[0]); // Update to a valid date format
        table.options.meta?.updateData(row.index, column.id, dateValue);
      } else {
        setValue(""); // Reset if invalid date
        table.options.meta?.updateData(row.index, column.id, "");
      }
    } else {
      table.options.meta?.updateData(row.index, column.id, value);
    }
  };

  return (
    <input
      type={inputType || "text"}
      className="form-control"
      value={displayValue}
      onChange={onChange}
      onBlur={onBlur}
    />
  );
}
