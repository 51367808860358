import React from "react";

export function BooleanCell({ getValue, row, column, table }) {
  const initialValue = getValue();
  const [value, setValue] = React.useState(initialValue);

  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const onChange = (e) => {
    setValue(e.target.value);
    table.options.meta?.updateData(row.index, column.id, e.target.value);
  };

  return (
    <select
      className="form-select"
      onChange={onChange}
      value={value || undefined}
    >
      <option value=""></option>
      <option value="true">Yes</option>
      <option value="false">No</option>
    </select>
  );
}
