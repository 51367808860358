import * as React from "react";
import { createColumnHelper } from "@tanstack/react-table";

import { InputCell } from "./InputCell";
import { BooleanCell } from "./BooleanCell";
import { SelectCell } from "./SelectCell";
import { CreatableSelectCell } from "./CreatableSelectCell";

const columnHelper = createColumnHelper();

function RemoveButton({ row, table }) {
  return (
    <button
      className="text-danger text-center btn btn-link btn-sm text-decoration-none"
      onClick={() => table.options.meta.removeRow(row.index)}
    >
      x
    </button>
  );
}

function SelectableCell({ cell, row, table, children }) {
  const cellRef = React.useRef(null);

  React.useEffect(() => {
    const handleClickOutside = (event) => {
      if (cellRef.current && !cellRef.current.contains(event.target)) {
        const selection =
          table.options.meta.selectedCells[`${row.id}-${cell.id}`];
        if (selection?.userId.toString() === table.options.meta.currentUserId) {
          table.options.meta.cellDeselected?.(row.id, cell.id);
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [row.id, cell.id, table.options.meta]);

  const onClick = () => {
    table.options.meta.cellSelected(row.id, cell.id);
  };

  const selection = table.options.meta.selectedCells?.[`${row.id}-${cell.id}`];
  const currentUserId = table.options.meta.currentUserId;
  const showIndicator =
    selection && selection.userId.toString() !== currentUserId;

  return (
    <div
      ref={cellRef}
      onClick={onClick}
      className={`${showIndicator ? "selected-cell" : ""}`}
    >
      {children}
      {showIndicator && (
        <div className="selected-cell--user">
          <div className="selected-cell--user-name">
            {selection.currentUser}
          </div>
        </div>
      )}
    </div>
  );
}

export const DeploymentTableColumns = [
  columnHelper.accessor(
    (row) => ({ value: row.deployment_status, label: row.deployment_status }),
    {
      id: "deployment_status",
      cell: (info) => (
        <SelectableCell {...info}>
          <SelectCell
            {...info}
            url="/deployments/deployment_statuses.json"
            colorSchemeKey="merchantStatus"
          />
        </SelectableCell>
      ),
      header: () => "Merchant Status",
      size: 250,
      meta: {
        name: "Merchant Status",
      },
    },
  ),
  columnHelper.accessor(
    (row) => (row.app_in_date ? new Date(row.app_in_date) : null),
    {
      id: "app_in_date",
      cell: (info) => (
        <SelectableCell {...info}>
          <InputCell {...info} inputType="date" />
        </SelectableCell>
      ),
      header: () => "App In Date",
      meta: {
        name: "App In Date",
      },
    },
  ),
  columnHelper.accessor(
    (row) => (row.e_sign_date ? new Date(row.e_sign_date) : null),
    {
      id: "e_sign_date",
      cell: (info) => (
        <SelectableCell {...info}>
          <InputCell {...info} inputType="date" />
        </SelectableCell>
      ),
      header: () => "E-Sign Date",
      meta: {
        name: "E-Sign Date",
      },
    },
  ),
  columnHelper.accessor(
    (row) => ({ value: row.application_status, label: row.application_status }),
    {
      id: "application_status",
      cell: (info) => (
        <SelectableCell {...info}>
          <SelectCell
            {...info}
            url="/deployments/application_statuses.json"
            colorSchemeKey="appStatus"
          />
        </SelectableCell>
      ),
      header: () => "App Status",
      meta: {
        name: "App Status",
      },
    },
  ),
  columnHelper.accessor(
    (row) => (row.approved_date ? new Date(row.approved_date) : null),
    {
      id: "approved_date",
      cell: (info) => (
        <SelectableCell {...info}>
          <InputCell {...info} inputType="date" />
        </SelectableCell>
      ),
      header: () => "Approved Date",
      meta: {
        name: "Approved Date",
      },
    },
  ),
  columnHelper.accessor(
    (row) => (row.deployment_date ? new Date(row.deployment_date) : null),
    {
      id: "deployment_date",
      cell: (info) => (
        <SelectableCell {...info}>
          <InputCell {...info} inputType="date" />
        </SelectableCell>
      ),
      header: () => "Deployment Date",
      meta: {
        name: "Deployment Date",
      },
    },
  ),
  columnHelper.accessor((row) => row.merchant, {
    id: "merchant_id",
    cell: (info) => (
      <SelectableCell {...info}>
        <CreatableSelectCell
          {...info}
          url="/merchants.json"
          objectName="merchant"
        />
      </SelectableCell>
    ),
    header: () => "Merchant",
    meta: {
      name: "Merchant",
    },
  }),
  columnHelper.accessor(
    (row) => ({ value: row.mid_type, label: row.mid_type }),
    {
      id: "mid_type",
      cell: (info) => (
        <SelectableCell {...info}>
          <SelectCell {...info} url="/deployments/mid_types.json" />
        </SelectableCell>
      ),
      header: () => "MID Type",
      meta: {
        name: "MID Type",
      },
    },
  ),
  columnHelper.accessor((row) => row.sales_channel, {
    id: "sales_channel_id",
    cell: (info) => (
      <SelectableCell {...info}>
        <CreatableSelectCell
          {...info}
          url="/sales_channels.json"
          objectName="sales_channel"
        />
      </SelectableCell>
    ),
    header: () => "Channel",
    meta: {
      name: "Channel",
    },
  }),
  columnHelper.accessor((row) => row.processor, {
    id: "processor_id",
    cell: (info) => (
      <SelectableCell {...info}>
        <CreatableSelectCell
          {...info}
          url="/processors.json"
          objectName="processor"
        />
      </SelectableCell>
    ),
    header: () => "Processor",
    meta: {
      name: "Processor",
    },
  }),
  columnHelper.accessor((row) => row.platform, {
    id: "platform_id",
    cell: (info) => (
      <SelectableCell {...info}>
        <CreatableSelectCell
          {...info}
          url="/platforms.json"
          objectName="platform"
        />
      </SelectableCell>
    ),
    header: () => "Platform",
    meta: {
      name: "Platform",
    },
  }),
  columnHelper.accessor((row) => row.bp_price, {
    id: "bp_price",
    cell: (info) => (
      <SelectableCell {...info}>
        <InputCell {...info} inputType="text" />
      </SelectableCell>
    ),
    header: () => "Price (BP)",
    meta: {
      name: "Price (BP)",
    },
  }),
  columnHelper.accessor((row) => row.per_transaction / 100, {
    id: "per_transaction",
    cell: (info) => (
      <SelectableCell {...info}>
        <InputCell {...info} inputType="number" />
      </SelectableCell>
    ),
    header: () => "Per Transaction",
    meta: {
      name: "Per Transaction",
    },
  }),
  columnHelper.accessor((row) => row.projected_volume / 100, {
    id: "projected_volume",
    cell: (info) => (
      <SelectableCell {...info}>
        <InputCell {...info} inputType="text" />
      </SelectableCell>
    ),
    header: () => "Projected Volume",
    meta: {
      name: "Projected Volume",
    },
  }),
  columnHelper.accessor((row) => row.gateway, {
    id: "gateway_id",
    cell: (info) => (
      <SelectableCell {...info}>
        <CreatableSelectCell
          {...info}
          url="/gateways.json"
          objectName="gateway"
        />
      </SelectableCell>
    ),
    header: () => "Gateway",
    meta: {
      name: "Gateway",
    },
  }),
  columnHelper.accessor((row) => row.point_of_sale, {
    id: "point_of_sale_id",
    cell: (info) => (
      <SelectableCell {...info}>
        <CreatableSelectCell
          {...info}
          url="/point_of_sales.json"
          objectName="point_of_sale"
        />
      </SelectableCell>
    ),
    header: () => "Solutions & POS",
    meta: {
      name: "Solutions & POS",
    },
  }),
  columnHelper.accessor(
    (row) => ({ value: row.cc_ach_form, label: row.cc_ach_form }),
    {
      id: "cc_ach_form",
      cell: (info) => (
        <SelectableCell {...info}>
          <SelectCell {...info} url="/deployments/cc_ach_forms.json" />
        </SelectableCell>
      ),
      header: () => "CC/ACH Form",
      meta: {
        name: "CC/ACH Form",
      },
    },
  ),
  columnHelper.accessor(
    (row) => ({
      value: row.hardware_payment_term,
      label: row.hardware_payment_term,
    }),
    {
      id: "hardware_payment_term",
      cell: (info) => (
        <SelectableCell {...info}>
          <SelectCell
            {...info}
            url="/deployments/hardware_payment_terms.json"
          />
        </SelectableCell>
      ),
      header: () => "HDW Payment Term",
      meta: {
        name: "HDW Payment Term",
      },
    },
  ),
  columnHelper.accessor(
    (row) => ({ value: row.deployment_type, label: row.deployment_type }),
    {
      id: "deployment_type",
      cell: (info) => (
        <SelectableCell {...info}>
          <SelectCell {...info} url="/deployments/deployment_types.json" />
        </SelectableCell>
      ),
      header: () => "Deployment Type",
      meta: {
        name: "Deployment Type",
      },
    },
  ),
  columnHelper.accessor((row) => row.pci_required, {
    id: "pci_required",
    cell: (info) => (
      <SelectableCell {...info}>
        <BooleanCell {...info} />
      </SelectableCell>
    ),
    header: () => "PCI",
    meta: {
      name: "PCI",
    },
  }),
  columnHelper.accessor((row) => row.fixed_rate, {
    id: "fixed_rate",
    cell: (info) => (
      <SelectableCell {...info}>
        <BooleanCell {...info} />
      </SelectableCell>
    ),
    header: () => "Fixed Rate Y to N's",
    meta: {
      name: "Fixed Rate Y to N's",
    },
  }),
  columnHelper.accessor((row) => row.notes, {
    id: "notes",
    cell: (info) => (
      <SelectableCell {...info}>
        <div className="link-cell d-flex gap-2 align-items-center">
          <a
            href={`/deployments/${info.row.id}/deployment_notes`}
            data-turbo-frame="modal"
          >
            <turbo-frame-tag id={`deployment_${info.row.id}_notes`}>
              {info.getValue()}
            </turbo-frame-tag>
          </a>
        </div>
      </SelectableCell>
    ),
    header: () => "Notes",
    meta: {
      name: "Notes",
    },
  }),
  columnHelper.display({
    id: "remove",
    cell: RemoveButton,
    enableHiding: false,
    meta: {
      name: "Remove",
    },
  }),
];
